import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["timezone", "form"]

  connect() {
    this.setTimezone()
    this.handleKeyPress = this.handleKeyPress.bind(this)
    document.addEventListener('keydown', this.handleKeyPress)
  }

  disconnect() {
    document.removeEventListener('keydown', this.handleKeyPress)
  }

  setTimezone() {
    this.timezoneTarget.value = Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  handleKeyPress(event) {
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      event.preventDefault()
      this.formTarget.requestSubmit()
    }
  }
}
