import {Controller} from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static values = { "requestId": Number }

  connect() {
    this.updates = [];
    this.interval = 0;
    this.calendulateChannel = consumer.subscriptions.create(
      { channel: "CalendulateChannel", id: this.requestIdValue },
      {
        received: (data) => {
          this.processData(data)
        }
      }
    );
  }

  disconnect() {
    if (this.calendulateChannel) {
      this.calendulateChannel.unsubscribe();
    }
    this.interval && clearInterval(this.interval);
  }

  processData(data) {
    if (data.completed) {
      document.getElementById('user_input_block').style.display = 'none';
      const frame = document.getElementById(`calendulate_request_${this.requestIdValue}`);
      if (frame) {
        document.getElementById(`calendulate_request_${this.requestIdValue}`).src = window.location.pathname;
      } else {
        Turbo.visit(window.location.pathname);
      }
      return;
    }
    this.updates.push(data.status_update);
    this.startProgressAnimation();
  }

  startProgressAnimation() {
    this.interval ||= setInterval(() => {
      this.tickProgress()
    }, 500)
  }

  tickProgress() {
    if (this.updates.length === 0) { return }

    this.element.querySelector('#calendulate-streaming-status').innerHTML = this.updates.shift()
  }
}
